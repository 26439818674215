import { css } from 'styled-components';
import { breakpoints } from '../../style-utilities/variables';
export default css`
  .hero {
    background-position: top;
    @media (min-width: ${breakpoints.tablet}) {
      :after {
        content: '';
        position: absolute;
        min-height: 550px;
        width: 100%;
        top: 124px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    @media (min-width: ${breakpoints.generalDesktop}) {
      :after {
        top: 76px;
      }
    }
  }
`;
