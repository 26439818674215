import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import PagesHero from '../components/PagesHero';
import ContactBox from '../components/ContactBox';
import Heading from '../components/Heading';
import placeHolderImage from '../images/placeholder.png';
import { breakpoints, colors } from '../style-utilities/variables';

import ComponentStyles from '../brand-styles/pages/faq-categories.styles';

const Div = styled.div`
  height: auto;

  .main-section {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 70% 30%;
    -ms-grid-columns: 70% 40px 1fr 40px;
    padding: 40px;
    margin: auto;
    .category-section {
      -ms-grid-column: 1;
    }
    .contact {
      -ms-grid-column: 3;
      text-align: center;
    }
  }
  .hero {
    background: ${props => `url(${props.bgImage}) no-repeat center`};
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .heading {
      margin-top: 0px;
      padding: 0 10px;
    }
  }
  .gray-container {
    background: #e0e0e0;
    margin-bottom: 20px;
    padding: 20px;
    .category-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
  .category-card {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 20% 80%;
    -ms-grid-columns: 20% 80%;
    grid-template-areas: 'img category';
    grid-gap: 15px;
    img {
      grid-area: img;
      width: 120px;
      margin: 0 auto 0px;
      padding-right: 12px;
    }
    h2 {
      grid-area: category;
      font-size: 28px;
      margin: 0;
      text-decoration: none;
    }
    p {
      color: ${colors.bodyText};
      margin-bottom: 0;
    }
    @media (-ms-high-contrast: none) {
      div {
        -ms-grid-column: 2;
        grid-column: 2;
      }
      div:first-child {
        -ms-grid-column: 1;
        grid-column: 1;
      }
    }
  }
  #icon {
    transform: scaleX(-1);
    width: 16px;
    height: 16px;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    .gray-container {
      padding: 15px;
    }
    .category-card {
      img {
        width: 70px;
        padding-right: 5px;
        padding-bottom: 5px;
      }
      .category-content {
        padding: 0 10px 0;

        h2 {
          font-size: 17px;
          margin: 0;
          text-decoration: none;
        }
        p {
        }
      }
    }
    .main-section {
      display: block;
      width: 100%;
      padding: 10px;
      .category-section {
        padding: 0;
      }
    }
  }
  a {
    text-decoration: none;
    color: ${colors.primary};
  }
  ${ComponentStyles}
`;

const FaqCategory = props => {
  const faqCategories = props.data.allWordpressWpFaqCategory.edges.map(
    ({ node: { slug, wordpress_id, name, description, acf } }) => {
      return (
        <Link key={wordpress_id} to={`/faq-categories/${slug}`}>
          <div className="gray-container">
            <div className="category-card">
              <div>
                {
                  <img
                    src={acf === null ? placeHolderImage : acf.image.source_url}
                    alt="faq category"
                  />
                }
              </div>
              <div className="category-content">
                <h2>{ReactHtmlParser(name)}</h2>
                <p>{ReactHtmlParser(description)}</p>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  );
  const heroImageUrl = props.data.wordpressPage.acf.hero_image ? props.data.wordpressPage.acf.hero_image.source_url : "";
  return (
    <Div bgImage={heroImageUrl}>
      <Helmet>
        <title>{props.data.wordpressPage.yoast_head_json.title}</title>
        <meta
          name="description"
          content={props.data.wordpressPage.yoast_head_json.description}
        />
      </Helmet>
      <PagesHero className="hero">
        <div className="InnerBox">
          <div className="title">
            <Heading>Frequently Asked Questions</Heading>
          </div>
        </div>
      </PagesHero>
      <div className="main-section">
        <div className="category-section">{faqCategories}</div>
        <div className="contact">
          <ContactBox>
            <h2>Have more questions or concerns?</h2>
            <Link to="/contact"><FontAwesomeIcon id="icon" icon={faEnvelope} /></Link>
            <br />
            <Link to="/contact"><h3>Contact Us</h3></Link>
          </ContactBox>
        </div>
      </div>
    </Div>
  );
};

export default FaqCategory;

export const query = graphql`
  {
    allWordpressWpFaqCategory {
      edges {
        node {
          wordpress_id
          name
          description
          slug
          acf {
            image {
              source_url
            }
          }
        }
      }
    }
    wordpressPage(slug: { eq: "faq" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        hero_image {
          source_url
        }
      }
    }
  }
`;
